<template>
  <!-- <main-wrapper :tool="false"> -->

    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>

          <div class="search-bars">
            <tool-tip :content="$t('updateVip.add')" placement="top" type="light-tip" :offset="-10" v-if="false">
               <!-- <el-button icon="el-icon-plus" @click="addHandle" type="primary" plain circle v-ripple></el-button> -->
              <el-button type="primary" plain  @click="addHandle" circle v-ripple>
                <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('updateVip.delete')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="deleteHandle" circle v-ripple>
                <i-icon name="iconshanchu"></i-icon>
              </el-button>
              <!-- <el-button @click="deleteHandle" icon="el-icon-delete" type="primary" plain circle v-ripple></el-button> -->
            </tool-tip>
            <!-- <el-button @click="addHandle" icon="el-icon-download" disabled v-ripple>导出</el-button> -->
            <search-group :options="options" @click="click" style="float:right;" @input="({select,value,Op}) => {searchName=select;searchText=value,searchOption=Op}"
              :field="{select: searchName, value: searchText}"></search-group>
            <el-checkbox v-model="hasChild" class="search-containChild">{{$t('updateVip.includeChild')}}</el-checkbox>
          </div>

          <el-table :data="tableData" class="v-table" @sort-change="sortChange" border @header-dragend="saveHeaderDragWidth" :height="tableHeight" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column type="selection" width="52px"></el-table-column>
            <el-table-column sortable="custom" prop="OrderID" :label="$t('updateVip.orderNo')" :width="TW($t('updateVip.orderNo'),180)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="HoldName" :label="$t('updateVip.holdName')" :width="TW($t('updateVip.holdName'),120)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="DeviceCount" :label="$t('updateVip.orderCount')" :width="TW($t('updateVip.orderCount'),100)"></el-table-column>
            <el-table-column sortable="custom" prop="Total" :label="$t('updateVip.orderPoint')" :width="TW($t('updateVip.orderPoint'),110)">
              <template slot-scope="{row}">
                <p style="color:#f44336;">-{{row.Total}}</p>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="StatusDesc" :label="$t('updateVip.orderStatus')" :width="TW($t('updateVip.orderStatus'),100)"></el-table-column>
            <el-table-column sortable="custom" prop="CreateMan" :label="$t('updateVip.orderUser')" :width="TW($t('updateVip.orderUser'),100)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="OrderTime" :label="$t('updateVip.createTime')" :width="TW($t('updateVip.createTime'),130)">
              <template slot-scope="{row}">{{miment(row.OrderTime).format('YYYY-MM-DD hh:mm')}}</template>
            </el-table-column>
            <el-table-column sortable="custom" prop="UpdateTime" :label="$t('updateVip.updateTime')" :width="TW($t('updateVip.updateTime'),130)">
              <template slot-scope="{row}">{{miment(row.UpdateTime).format('YYYY-MM-DD hh:mm')}}</template>
            </el-table-column>
            <el-table-column label="" :resizable="false">
              <template slot-scope="{row}">
                <p class="i-link" @click="openDialogOrderDetail(row)">{{$t('updateVip.view')}}</p>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            class="v-pager"
            :page-size="pageSize"
            :pager-count="11"
            :current-page.sync="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            :page-sizes="pageSizes"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

          <transition name="scale-grow" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition>


          <el-dialog :visible.sync="dialogDetail" :title="$t('updateVip.updateVip')" custom-class="v-dialog v-dialog--md v-dialog--mdpadding"  @closed="dialogDetailClosed">
            <div class="batch-wrapper batch-wrapper--flex" v-loading="payLoading">
              <div class="batch-nav batch-nav--is-tree">
                <tree-user
                  ref="treeUser"
                  :title="$t('updateVip.selectVehicle')"
                  show-icon
                  show-vehicle
                  show-object-count
                  :use-local-setting="false"
                  release-check
                  @check="treeCheck"
                ></tree-user>
              </div>
              <div class="batch-body" v-if="dialogDetailStep===PRE_ORDER||dialogDetailStep===IN_ORDER||dialogDetailStep===IN_WECHAT_IMAGE">
                <div class="top">
                  <img class="img" src="../../assets/images/wallet.png"/>
                  <p class="wallet-remain">{{$t('updateVip.remain')}}：{{remain}} {{$t('updateVip.vpoint')}}</p>
                  <el-button class="iml-0" type="primary" size="mini" round @click="goWallet">{{$t('updateVip.addPoint')}}</el-button>
                </div>
                <p class="item iml-0">{{$t('updateVip.payAmount')}}<em style="float:right;color:#ccc;">{{$t('updateVip.feeTip', {price1: this.oneYearPrice, price2: this.oneYearPriceWire})}}</em></p>
                <div class="options">
                  <div class="option" :class="{active:item.value === form.PayYear}" v-for="item in payOptions" :key="item.value" @click="changePayYear(item)">{{item.label}} {{$t('updateVip.year')}}</div>
                </div>
                <div class="info">
                  <div class="imb-0" style="height:36px;overflow-y:hidden;">
                    <el-button @click="openDialog" style="font-size:16px;float:right;" :loading="btnLoading" type="text">{{$t('updateVip.selected')}} {{checkedObjectKeys.length}} {{$t('updateVip.deviceUnit')}}{{$t('updateVip.device')}}</el-button>
                  </div>
                  <p style="float:right;text-align:right">{{$t('updateVip.payFee')}}: <span class="money">{{payTotal}}</span> {{form.PayMethod==1 ? $t('updateVip.vpoint') : $t('updateVip.payUnit')}}</p>
                </div>

                <div class="bottom">
                  <p class="item iml-0">{{$t('updateVip.payMethod')}}</p>
                  <el-row type="flex" justify="space-between">
                    <el-button class="border" type="primary" plain @click="payCheckOrder(2)">
                      <el-row type="flex" align="middle" justify="center"><i-icon name="icon-weixinzhifu1"></i-icon>{{$t('updateVip.wechat')}}</el-row>
                    </el-button>
                    <el-button class="border" type="primary" plain @click="payCheckOrder(3)">
                      <el-row type="flex" align="middle" justify="center"><i-icon name="icon-zhifubao"></i-icon>{{$t('updateVip.alipay')}}</el-row>
                    </el-button>
                    <el-button class="border" type="primary" plain @click="payCheckOrder(1)">
                      <el-row type="flex" align="middle" justify="center"><img src="../../assets/icon/VPoint.png" alt="">{{$t('updateVip.vpoint')}}</el-row>
                    </el-button>
                  </el-row>
                </div>

              </div>
              <div class="batch-body wrapper--success" v-if="dialogDetailStep===COMPLETED_ORDER">
                <i-icon name="icon-shengjichenggong"></i-icon>
                <p class="success-tip">{{$t('updateVip.updateSuccess')}}</p>
                <el-button class="bottom" type="warning" @click="close" size="medium">{{$t('updateVip.viewOrder')}}</el-button>
              </div>
              <transition name="layer">
                <div class="batch-body floatLayer" v-if="dialogDetailStep===IN_ORDER">
                  <h2>{{$t('updateVip.orderTip1')}}：</h2>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderName')}}：</span>
                    <span class="orderText"><img src="../../assets/icon/vip.png"/> {{$t('updateVip.updateVip')}} </span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderAmount')}}：</span>
                    <span class="orderText"><span class="important">{{form.PayYear}}</span> {{$t('updateVip.year')}}</span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderVehicle')}}：</span>
                    <span class="orderText">{{$t('updateVip.total')}} <span class="important">{{checkedObjectKeys.length}}</span> {{$t('updateVip.deviceUnit')}}{{$t('updateVip.device')}}</span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.orderMethod')}}：</span>
                    <span class="orderText">
                      <i-icon name="icon-weixinzhifu1" v-if="form.PayMethod===2"></i-icon>
                      <i-icon name="icon-zhifubao" v-if="form.PayMethod===3"></i-icon>
                      <img src="../../assets/icon/VPoint.png" class="img" v-if="form.PayMethod===1" alt="">
                      {{payMethodText}}
                    </span>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderTitle">{{$t('updateVip.payFee')}}：</span>
                    <span class="orderText"><span class="important">{{payTotal}}</span> {{payMethodSuffix}}</span>
                  </p>
                  <div class="orderButtons">
                    <el-button class="orderPay" size="medium" @click="pay">{{$t('updateVip.nextButtonText')}}</el-button>
                    <el-button class="orderPay cancel" size="medium" @click="cancelPay">{{$t('updateVip.backBUttonText')}}</el-button>
                  </div>
                </div>
              </transition>
              <transition name="layer">
                <div class="batch-body floatLayer payImg" v-if="dialogDetailStep===IN_WECHAT_IMAGE">
                  <h2>{{$t('updateVip.orderTip2')}}</h2>
                  <p>
                    <img :src="wxImgUrl"/>
                  </p>
                  <p class="orderItem clearfix">
                    <span class="orderText">{{$t('updateVip.payFee')}}：<span class="important">{{payTotal}}</span> {{payMethodSuffix}}</span>
                  </p>
                  <i-icon name="icon-weixinzhifu" class="weixinzhifu"></i-icon>
                  <p class="cancelText" @click="cancelPay">{{$t('common.back')}}</p>
                </div>
              </transition>
            </div>
          </el-dialog>

          <el-dialog :visible.sync="dialogObjectList" :title="$t('updateVip.selectedList')" custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" class="v-dialog-commandlist" append-to-body>
            <el-table :data="checkedObjectList" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" size="mini" max-height="528" stripe ref="multiTable">
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column :label="$t('updateVip.vehicleName')" prop="VehicleName" width="130" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.deviceID')" prop="SIM2" width="130" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.sim')" prop="SIM" width="120" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.vipEndDate')" prop="PayEndDate" width="115">
                <template slot-scope="{row}">
                  <p v-if="!row.PayEndDate || miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('updateVip.expired')}}</p>
                  <p v-else-if="miment(row.PayEndDate).getTime() < miment().add(90, 'DD').getTime()" style="color:#f44336;">{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                  <p v-else>{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.trackerType')" width="110">
                <template slot-scope="{row}">
                  <p style="color:#3F51B5;">{{$t('dict')[row.TrackerTypeName]}}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.online')" width="75">
                <template slot-scope="{row}">
                  <span class="square online" v-if="row.isOnline"></span>
                  <span class="square offline" v-else></span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.holdName')" prop="HoldName"  show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.createTime')" prop="CreateTime" width="120">
                <template slot-scope="{row}">
                  {{miment(row.CreateTime).format("YYYY-MM-DD")}}
                </template>
              </el-table-column>
            </el-table>
            <div slot="footer">
              <el-button @click="removeSelection">{{$t('common.removeChecked')}}</el-button>
              <el-button @click="removeAll">{{$t('common.removeAll')}}</el-button>
              <el-button @click="dialogObjectList=false">{{$t('common.close')}}</el-button>
            </div>
          </el-dialog>

          <el-dialog :visible.sync="orderDetailListVisible" :title="$t('updateVip.orderDetail')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer" append-to-body>
            <el-table :data="orderDetailList" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" size="mini" max-height="348" stripe ref="multiTable">
              <el-table-column :label="$t('updateVip.vehicleName')" prop="VehicleName" width="150" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.holdName')" prop="Name" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('updateVip.deviceID')" prop="SIM2" width="140">
                <span slot-scope="{row}">{{row.SIM2||row.SIM}}</span>
              </el-table-column>
              <el-table-column :label="$t('updateVip.amount')" prop="ServiceEndDate" width="110">
                <template slot-scope="{row}" >
                  <p>{{row.Months % 12 === 0 ? row.Months/12 : row.Months}} {{row.Months % 12 === 0?$t('updateVip.year'):$t('updateVip.month')}}</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('updateVip.vipEndDate')" width="140">
                <template slot-scope="{row}" >
                  <p>{{miment(row.EndTime).format('YYYY-MM-DD')}}</p>
                </template>
              </el-table-column>
            </el-table>
            <div slot="footer">
              <el-button @click="orderDetailListVisible=false">{{$t('common.close')}}</el-button>
            </div>
          </el-dialog>

          <el-dialog :title="$t('updateVip.wallet')" append-to-body width="400px" :visible.sync="dialogInfoVisible" custom-class="v-dialog v-dialog--wallet">
            <wallet-info v-if="dialogInfoVisible" @close="() => dialogInfoVisible = false" @send="send"></wallet-info>
          </el-dialog>

          <el-dialog :title="$t('updateVip.sendVPoint')" append-to-body custom-class="v-dialog v-dialog--sm v-dialog--mdpadding" :visible.sync="dialogSendVisible">
            <wallet-send v-if="dialogSendVisible" @close="() => dialogSendVisible = false"></wallet-send>
          </el-dialog>
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import { deleteFromArray } from '@/common/utils'
import mixin from '@/views/manage/mixin'
import * as API from '@/api/fee'
import {Filter, Op} from '@/api/config'
import { GetObjectListByObjectIDs } from '@/api/command'
import TreeUser from '@/views/common/tree-user'
import { GetObjectIdByMixID } from '@/api/common'
import miment from 'miment'
import * as Wallet from '@/api/wallet'
import WalletInfo from '@/views/wallet/wallet-info'
import WalletSend from '@/views/wallet/wallet-send'
import {BASE_URL} from '@/api/config'
import {getToken} from '@/common/auth'
const PRE_ORDER = 1
const IN_ORDER = 2
const IN_WECHAT_IMAGE = 3
const COMPLETED_ORDER = 9

export default {
  name: 'batchExpire',
  data() {
    return {
      miment,
      options: [
        { label: '订单号', value: 'm.OrderID', Op: Op.Contains },
        { label: '用户', value: 'h.Name', Op: Op.Contains },
        { label: '缴费人', value: 'u.UserName', Op: Op.Contains }
      ],
      payOptions: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ],
      payLoading: false,
      btnLoading: false,
      riskListOptions: [],
      checkedKeys: [],
      dialogDetail: false,
      dialogDetailVisible: false,
      // 1 选择车辆与支付方式页面
      // 2 订单确认页面
      // 3 微信支付扫码页面
      // 9 支付成功页面
      PRE_ORDER,
      IN_ORDER,
      IN_WECHAT_IMAGE,
      COMPLETED_ORDER,
      dialogDetailStep: PRE_ORDER,
      dialogObjectList: false,
      checkedObjectKeys: [],
      checkedObjectList: [],
      orderDetailList: [],
      orderDetailListVisible: false,
      oneYearPrice: 12,
      oneYearPriceWire: 6,
      remain: null,
      form: {
        PayYear: 1,
        PayMethod: 1
      },
      dialogInfoVisible: false,
      dialogSendVisible: false,
      tip: 'VIP车辆保存90天以上历史数据，开放全部功能；免费车辆保存7天历史数据，无VIP功能，例如：拍照，电子围栏，油量，温度等。',
      isAnimating: false, // 是否正在动画
      wxImgUrl: '',
      aliPayUrl: '',
      orderNo: '',
      feeLoading: false,
      payTotal: 0
    }
  },
  computed: {
    // payTotal() {
    //   return this.oneYearPrice * this.form.PayYear * this.checkedObjectKeys.length
    // },
    payAmountText() {
      return ['', '1', '2', '3'][this.form.PayYear]
    },
    payMethodText() {
      return ['', this.$t('updateVip.vpoint'), this.$t('updateVip.wechat'), this.$t('updateVip.alipay')][this.form.PayMethod]
    },
    payMethodSuffix() {
      return ['', this.$t('updateVip.vpoint'), this.$t('updateVip.payUnit'), this.$t('updateVip.payUnit')][this.form.PayMethod]
    },
    checkedObjectKeysLength() {
      return this.checkedObjectKeys.length
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    VclCode,
    TreeUser,
    WalletInfo,
    WalletSend
  },
  mixins: [
    mixin
  ],
  watch:{
    '$i18n.locale': 'getOptions',
    'checkedObjectKeysLength': 'fetchTotalFee',
    'form.PayYear': 'fetchTotalFee'
  },
  created() {
    this.miment = miment
    this.getOptions()
    this.searchName = this.options[0].value
    this.queryHandle()
  },
  methods: {
    getOptions() {
      this.options = [
        { label: this.$t('updateVip.orderNo'), value: 'm.OrderID', Op: Op.Contains },
        { label: this.$t('updateVip.holdName'), value: 'h.Name', Op: Op.Contains },
        { label: this.$t('updateVip.orderUser'), value: 'u.UserName', Op: Op.Contains }
      ]
    },
    fetchData() {
      const promise = new Promise((resolve) => {
        Wallet.GetWalletInfo(null).then(ret => {
          this.remain = ret.data.TotalBalance
          this.oneYearPrice = ret.data.key_price
          this.oneYearPriceWire = ret.data.key_price2
          resolve()
        })
      })
      return promise
    },
    fetchMixIds() {
      this.btnLoading = true
      GetObjectIdByMixID(this.checkedKeys, this.currentCommand).then(ret => {
        this.btnLoading = false
        this.checkedObjectKeys = ret.data
      })
    },
    fetchTotalFee() {
      this.feeLoading = true
      API.GetTotalFeeByIDs(this.checkedObjectKeys, this.form.PayYear).then(ret => {
        this.feeLoading = false
        this.payTotal = ret.data.totalFee
      })
    },
    fetchObjectList() {
      this.btnLoading = true
      const promise = new Promise((resolve) => {
        GetObjectListByObjectIDs(this.checkedObjectKeys).then(ret => {
          this.btnLoading = false
          this.checkedObjectList = ret.data.list
          resolve()
        })
      })
      return promise
    },
    fetchMultiOrderDetail(orderId) {
      const promise = new Promise((resolve) => {
        API.QueryMultiOrderDetail(orderId).then(ret => {
          resolve(ret.data)
        })
      })
      return promise
    },
    async openDialog() {
      await this.fetchObjectList()
      this.dialogObjectList = true
    },
    async openDialogOrderDetail(row) {
      const list = await this.fetchMultiOrderDetail(row.OrderID)
      this.orderDetailList = list
      this.orderDetailListVisible = true
    },
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    rowClick(row) {
      this.$router.push(`/risk/people/detail?id=${row.RID}`)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    async addHandle() {
      await this.fetchData()
      this.dialogDetail = true
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }

      const targetRows = rows || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.OrderID)).then(() => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          this.$message({
            type: 'success',
            message: '删除成功，只删除[等待续费]订单',
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }

          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    getRiskTypes(ids) {
      const idArr = ids.split(',').map(k => parseInt(k))
      const types = this.riskListOptions.filter(k => {
        return idArr.indexOf(k.RID) != -1
      }).map(k => k.RiskType)
      return types
    },
    refreshHandle() {
      this.queryHandle()
    },
    removeSelection() {
      // 移除选中行
      const list = this.$refs.multiTable.selection
      list.forEach(k => {
        deleteFromArray(this.checkedObjectList, j => j.ObjectID === k.ObjectID)
        deleteFromArray(this.checkedObjectKeys, j => j === k.ObjectID)
      })

      var targetList = this.checkedObjectKeys.map(k => k + 10000000)
      this.$refs.treeUser.setCheckedKeys(targetList)
    },
    removeAll() {
      // 移除全部行
      this.checkedObjectList.splice(0)
      this.checkedObjectKeys.splice(0)
      this.$refs.treeUser.setCheckedKeys([])
    },
    treeCheck(keys) {
      this.checkedKeys = keys.slice()
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.fetchMixIds()
        this.timer = null
      }, 50)
    },
    dialogDetailClosed() {
      this.dialogDetailStep = PRE_ORDER
      this.checkedObjectKeys.splice(0)
      this.checkedObjectList.splice(0)
      this.$refs.treeUser.setCheckedKeys([])
      this.endCheckWxPay()
    },
    changePayYear(item) {
      this.form.PayYear = item.value
    },
    cancelPay() {
      this.dialogDetailStep = PRE_ORDER
      this.endCheckWxPay()
    },
    payCheckOrder(method) {
      if (this.checkedObjectKeys.length === 0) {
        this.$alert(this.$t('updateVip.pleaseSelectVehicle'))
        return
      }
      this.form.PayMethod = method
      // 如果是支付宝
      if (this.form.PayMethod === 3) {
        // 创建支付宝支付充值钱包订单
        API.UpdateVIPAliPay(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = IN_ORDER
            this.orderNo = ret.data.orderNo
            this.aliPayUrl = `${BASE_URL}/pay/AlipayByOrder?loginKey=${getToken()}&totalPoints=${this.payTotal}&OrderNo=${this.orderNo}&format=json`
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      }
      this.dialogDetailStep = IN_ORDER
    },
    pay() {

      if (this.form.PayMethod === 2) {
        // 创建微信支付充值钱包订单
        this.payLoading = true

        API.UpdateVIPWechat(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = IN_WECHAT_IMAGE
            this.wxImgUrl = `${BASE_URL}/pay/Image?text=${ret.data.code_url}`
            this.orderNo = ret.data.orderNo
            this.startCheckWxPay()
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
        return
      } else if (this.form.PayMethod === 3){
        this.startCheckWxPay()
        window.open(this.aliPayUrl)
      } else {
        this.payLoading = true
        API.Add(this.checkedObjectKeys, this.form.PayYear).then(ret => {
          this.payLoading = false
          if (!ret.data.errCode) {
            this.dialogDetailStep = COMPLETED_ORDER
          } else if (ret.data.errMsg){
            this.$alert(ret.data.errMsg)
            console.error(ret.data)
          } else {
            console.error(ret.data)
          }
        }).catch(err => {
          this.payLoading = false
          this.$message({
            message: err,
            type: 'error'
          })
        })
      }
    },
    startCheckWxPay() {
      API.IsWxPaySuccess(this.orderNo).then(ret => {
        if (ret.data.isSuccess) {
          this.payLoading = true
          API.Add(this.checkedObjectKeys, this.form.PayYear).then(ret => {
            this.payLoading = false
            if (!ret.data.errCode) {
              this.dialogDetailStep = COMPLETED_ORDER
            } else if (ret.data.errMsg){
              this.$alert(ret.data.errMsg)
              console.error(ret.data)
            } else {
              console.error(ret.data)
            }
          }).catch(err => {
            this.payLoading = false
            this.$message({
              message: err,
              type: 'error'
            })
          })
        } else {
          this.startCheckWxPay_timer = setTimeout(() => {
            this.startCheckWxPay()
          }, 2000)
        }
      })
    },
    endCheckWxPay() {
      if (this.startCheckWxPay_timer) {
        clearTimeout(this.startCheckWxPay_timer)
        this.startCheckWxPay_timer = null
      }
    },
    close() {
      this.dialogDetail = false
      this.queryHandle()
    },
    send() {
      this.dialogInfoVisible = false
      this.dialogSendVisible = true
    },
    goWallet() {
      this.dialogDetail = false
      this.dialogInfoVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
$payBackgroundImage: linear-gradient(to bottom, #ef5350 0%, #d32f2f 100%);
$payBackgroundImageHover: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
.v-table {
  width: 100%;
}
.v-pager {
  text-align: right;
  & ::v-deep .el-pagination__total {
    float: left;
  }
  & ::v-deep .el-pagination__sizes {
    float: left;
  }
  & ::v-deep .el-input__inner {
    border-radius: 2px !important;
  }
}
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 8px;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}
.riskitem {
  color: #f44336;
  display: block;
  width: 80px;
}

.v-dialog-commandlist {
  // & ::v-deep .el-table--mini th, .el-table--mini td {
  //   padding: 3px 0;
  // }
  .square {
    height: 12px;
    width: 12px;
    display: inline-block;
    border-radius: 2px;
    &.online {
      background-color: #4CAF50;
    }
    &.offline {
      background-color: #BDBDBD;
    }
  }
}

.batch-wrapper {
  height: 590px;
  position: relative;
  &.batch-wrapper--flex {
    display: flex;
    .batch-nav {
      width: 450px;
      flex-shrink: 0;
      &.batch-nav--is-tree {
        position: relative;
      }
    }
    .batch-body {
      flex-grow: 1;
      margin: 0 15px;
      margin-right: 0;
      position: relative;
      &.wrapper--success {
        background-color: #89ABE3;
        .icon {
          font-size: 128px;
          margin: 24px auto;
          display: block;
        }
      }
      .options {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
        .option {
          cursor: pointer;
          width: calc(33% - 8px);
          background-color: #F8F8F8;
          height: 40px;
          line-height: 40px;
          text-align: center;
          &.active {
            background-color: #67C23A;
            color: #FFF;
          }
          &:hover {
            background-color: #67C23A;
            color: #FFF;
          }
        }
      }
      .tip {
        color: #67C23A;
        margin-top: 15px;
      }
      .top {
        height: 100px;
        display: flex;
        align-items: center;
        background-color: #89ABE3;
        .img {
          width: 60px;
          height: 60px;
          margin-left: 20px;
        }
        .wallet-remain {
          font-size: 20px;
          margin-left: 20px;
          color: #FFF;
        }
      }
      .item {
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        color: #303133;
      }
      .info {
        position: absolute;
        left: 0;
        bottom: 61px;
        width: 100%;
        z-index: 1;
        p {
          font-size: 18px;
          .money {
            color: rgb(255, 102, 0);
          }
        }
      }
      .bottom {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        .el-row {
          .el-button {
            flex: 1 0 calc(33% - 8px);
            background-image: linear-gradient(to bottom, #ef5350 0%, #d32f2f 100%);
            & ::v-deep span {
              color: white;
            }
            &:hover {
              background-image: linear-gradient(to bottom, #e57373 0%, #d32f2f 100%);
            }
            .icon {
              font-size: 24px;
              margin-right: 8px;
            }
            img {
              height: 23px;
              margin-right: 8px;
            }
          }
        }

      }
      .success {
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      .success-tip {
        color: #ECEFF1;
        font-size: 13px;
        line-height: 25px;
        padding: 24px;
        text-align: center;
      }

      &.floatLayer {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        background-color: rgba(255,255,255, .95);
        //padding-left: 50px;
        $fWidth: 24em;
        h2 {
          margin-bottom: 30px;
          margin-top: 20px;
          color: #303133;
          max-width: 336px;
          margin-left: auto;
          margin-right: auto;
          padding-left: 70px;
        }

        .orderItem {
          line-height: 38px;
          height: 38px;
          font-size: 14px;
          max-width: $fWidth;
          margin: 0 auto;
          padding-left: 5em;
          .orderTitle {
            width: 6em;
            text-align: left;
            float: left;
            color: #303133;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .orderText {
            margin-left: 6em;
            width: calc(#{$fWidth} - 11em);
            text-align: left;
            display: block;
            img {
              vertical-align: sub;
              &.img {
                height: 18px;
              }
            }
            span.important {
              color: rgb(255, 102, 0);
            }
            .icon {
              font-size: 16px;
              margin-right: 4px;
            }
          }
        }
        .orderButtons {
          max-width: $fWidth;
          margin: 0 auto;
          padding-left: 5em;
          .orderPay {
            margin-top: 30px;
            color: #FFF;
            background-image: $payBackgroundImage;

            &:hover {
              background-image: $payBackgroundImageHover;
            }
            &.cancel {
              background-image: linear-gradient(to bottom, #BDBDBD 0%, #757575 100%);
              &:hover {
                background-image: linear-gradient(to bottom, #E0E0E0 0%, #757575 100%);
              }
            }
          }
        }

        &.payImg {
          h2 {
            margin: 40px 0;
            font-weight: 500;
            max-width: 100%;
            padding-left: 0;
          }
          text-align: center;
          padding-left: 0;
          .orderItem {
            padding-left: 0;
            text-align: center;
            .orderText {
              margin-left: 0;
              margin-top: 25px;
              font-size: 18px;
              text-align: center;
              width: 100%;
            }
          }
          .weixinzhifu {
            font-size: 128px;
          }
          .cancelText {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.layer-leave-active {
  display: none;
}
.layer-enter-active, .layer-leave-active {
  transition: all 0.3s;
}
.layer-enter, .layer-leave-to
/* .list-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.5);
  transform-origin: 50% 0;
}
</style>
